import React from 'react';
import './Programs.css'
import {ProgramsData, programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

const Programs = () => {
    return (
        <div className="programs" id="programs">
            {/* header */}
            <div className="programs-header">
                <span className='stroke-text'>explore our</span>
                <span>programs</span>
                <span className='stroke-text'>to shape you</span>
            </div>

            <div className="program-categories">
                {programsData.map((Program, i)=>(
                    <div className="category" key={i}>
                        {Program.image}
                        <span>{Program.heading}</span>
                        <span>{Program.details}</span>
                        <div className="join-now">
                            <span>Join Now</span>
                            <img src={RightArrow} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Programs;
