import React from 'react';
import './Footer.css'
import Logo from '../../assets/logo.png'
import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";




const Footer = () => {
    return (
        <div className="footer-container">
            <hr />
            <div className="footer">
                <div className="social-links">
                <FaGithub/>
                <FaInstagram/>
                <TiSocialLinkedin/>
                </div>
                <div className="logo-f">
                <img src={Logo} alt="" />
                <p className='copyright'>
                &copy; 2024 FitClub - Developed By <a href="https://amirmazban.com/">Amir Mazban</a>
                </p>
            </div>
            </div>

            <div className='blur blur-f-1'></div>
            <div className='blur blur-f-2'></div>
        </div>
    );
}

export default Footer;
