import React, { useState } from 'react';
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { IoIosClose } from "react-icons/io";
import { Link } from 'react-scroll';


  const Header = () => {

    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpened , setMenuOpened] = useState(false)

    return (
        <div>
            <div className="header">

                <img src={Logo} alt="logo" className='logo' />
                {(menuOpened === false && mobile === true) ? (
                    <div className='bars'
                    onClick={() => setMenuOpened(true)}
                    
                    ><img src={Bars} className='bars-icon'></img></div>
                ) : 
                <ul className='header-menu'>
                    <IoIosClose className='close-icon' onClick={() => setMenuOpened (false)} />
                    <li>
                        <Link
                        onClick={() => setMenuOpened (false)}
                        to='home'
                        spy={true}
                        smooth={true}
                        >Home</Link>
                        </li>
                    <li><Link
                     onClick={() => setMenuOpened (false)}
                     to='programs'
                    spy={true}
                    smooth={true}
                    >Programs</Link></li>
                    <li><Link
                    onClick={() => setMenuOpened (false)}
                    to='reasons'
                    spy={true}
                    smooth={true}
                    >Why us</Link></li>
                    <li><Link
                     onClick={() => setMenuOpened (false)}
                     to='plans'
                    spy={true}
                    smooth={true}
                    >Plans</Link></li>
                    <li><Link 
                    onClick={() => setMenuOpened (false)}
                    to='testimonials' 
                    spy={true}
                    smooth={true}
                    >
                    Testimonials</Link></li>
                </ul>
                }

                
            </div>
        </div>
    );
}

export default Header;
